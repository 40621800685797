import {
    SERVICE as request,
    SERVICEDEFAULT as requestbody,
    upload
} from "./request.js"
//开发环境
// const API = '/yf-wx-server';
// const API1 = '/yf-server'
// const API2 = 'http://test.youfu.chnyoufu.com'
//测试环境
// const API = '/yf-wx-server';
// const API1 = '';
// const API2 = 'http://test.youfu.chnyoufu.com'
// const API3 = 'http://testapi.chnyoufu.com'
//生产环境
const API = '';
const API1 = '';
const API2 = 'https://api.chnyoufu.com'
const API3 = 'https://api.chnyoufu.com'
//查询订单挂起原因列表
export function queryHangConfigList(query) {
    return request({
        url: `${API}/api/wx/order/queryHangConfigList/${query}.json`,
        method: "get",
        data: query
    });
}
//订单挂起
export function feedbackOrderHangEx(query) {
    return requestbody({
        url: `${API}/api/wx/order/feedbackOrderHangEx.json`,
        method: "post",
        data: query
    });
}
//取消订单挂起
export function handleOrderHangEx(query) {
    return requestbody({
        url: `${API}/api/wx/order/handleOrderHangEx.json`,
        method: "post",
        data: query
    });
}
//订单信息反馈
export function feedbackOrderEngineerEx(query) {
    return requestbody({
        url: `${API}/api/wx/order/feedbackOrderEngineerEx.json`,
        method: "post",
        data: query
    });
}
//反馈历史查询
export function feedbackEngineerExList(query) {
    return requestbody({
        url: `${API}/api/wx/order/feedbackEngineerExList.json`,
        method: "post",
        data: query
    });
}
//反馈详情
export function feedbackEngineerExInfo(query) {
    return requestbody({
        url: `${API}/api/wx/order/feedbackEngineerExInfo.json`,
        method: "post",
        data: query
    });
}
//视频列表
export function studyVideoList(query) {
    return requestbody({
        url: `${API1}/api/app/studyVideoList.json`,
        method: "post",
        data: query
    });
}

//图片上传
export function uploadFile(query) {
    return requestbody({
        url: `${API}/api/wx/order/aliyunOssPolicy/2.json`,
        method: "get",
        params: query
    });
}
export function ossUploadFile(formData) {
    //return upload(`https://wxxupload.chnyoufu.com/`, formData);
    return upload(`https://oss.chnyoufu.com/`, formData);
}


// APP获取服务凭证模板数据
export function serviceVoucherTempletInfoAPP(query) {
    return requestbody({
        url: `${API1}/api/app/templet/serviceVoucherTempletInfo.json`,
        method: "post",
        params: query
    });
}

// 微信获取服务凭证模板数据
export function serviceVoucherTempletInfoWX(query) {
    return requestbody({
        url: `${API}/api/wx/templet/serviceVoucherTempletInfo.json`,
        method: "post",
        params: query
    });
}

// APP上传服务凭证信息
export function updateOrderTrackAPP(query) {
    return request({
        url: `${API1}/api/app/templet/updateOrderTrack.json`,
        method: "post",
        data: query
    });
}

// 微信上传服务凭证信息
export function updateOrderTrackWX(query) {
    return request({
        url: `${API}/order/updateOrderTrack.json`,
        method: "post",
        data: query
    });
}
// 微信授权
export function getJsConfig(query) {
    return requestbody({
        url: `${API}/index/getJsConfig.json`,
        method: "post",
        params: query
    });
}
//工程师&用户
export function validateTrackHangEngineer(query) {
    return requestbody({
        url: `${API}/api/wx/order/validateTrackHangEngineer.json`,
        method: "post",
        data: query
    });
}
//获取推荐天数
export function getOrderInsDate(query) {
    return requestbody({
        url: `${API}/api/wx/order/getOrderInsDate.json`,
        method: "post",
        data: query
    });
}

// 查询钉钉通知是否已经领取或接收
export function queryDingDingNoticeStatus(query) {
    return requestbody({
        url: `${API2}/api/order/home/main/project/task/ding/queryDingDingNoticeStatus`,
        method: "post",
        data: query
    });
}
// 下发钉钉任务
export function DingDingbuttonOnClick(query) {
    return requestbody({
        url: `${API2}/api/order/home/main/project/task/ding/buttonOnClick`,
        method: "post",
        data: query
    });
}

// 获取服务凭着模板（新）
export function queryServiceTemplateInfo(query) {
    return requestbody({
        url: `${API3}/api/business/serviceVoucherTemplet/queryServiceTemplateInfo`,
        method: "post",
        data: query
    });
}
//保存上传信息
export function saveServiceInfo(query) {
    return requestbody({
        url: `${API3}/api/business/serviceVoucherTemplet/saveServiceInfo`,
        method: "post",
        data: query
    });
}

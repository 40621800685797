import axios from "axios";
import Qs from "qs";
// const baseURL = "/yf-wx-server"
//请求头为application/x-www-form-urlencoded的配置
const SERVICE = axios.create({
    //baseURL:baseURL,
    timeout: 60000,
    transformRequest: [
        function (data) {
            //将对象 序列化成URL的形式，以&进行拼接
            data = Qs.stringify(data);
            return data;
        }
    ],
    headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
    }
});

//请求拦截器
SERVICE.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么，例如加入token
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

//响应拦截器
SERVICE.interceptors.response.use(function (response) {
    // 在接收响应做些什么，例如跳转到登录页
    return response.data;
}, function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
});
SERVICE.all = axios.all; // all方法没有挂载到 axios 实例对象中

//请求头为application/json的配置
const SERVICEDEFAULT = axios.create({
    //baseURL:baseURL,
    timeout: 60000,
    headers: {
        "Content-Type": "application/json;charset=UTF-8"
    }
});

//请求拦截器
SERVICEDEFAULT.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么，例如加入token
    if (sessionStorage.getItem('accessToken')) {
        config.headers.AccessToken = sessionStorage.getItem('accessToken')
    }
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

//响应拦截器
SERVICEDEFAULT.interceptors.response.use(function (response) {
    // 在接收响应做些什么，例如跳转到登录页
    return response.data;
}, function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
});
SERVICEDEFAULT.all = axios.all; // all方法没有挂载到 axios 实例对象中

function upload(url, data) {
    return new Promise((resolve, reject) => {
        axios
            .post(url, data, {
                headers: {
                    "Content-Type": "multipart/form-data;charset=UTF-8"
                }
            })
            .then(
                res => {
                    // if (res.status == 200) {
                    //     Message({
                    //         showClose: true,
                    //         message: "上传成功",
                    //         type: "success"
                    //     });
                    //     resolve(res);
                    // } else {
                    //     Message({
                    //         showClose: true,
                    //         message: res.data.message,
                    //         type: "warning"
                    //     });
                    //     resolve(res);
                    // }
                    return res.data;
                },
                err => {
                    reject(err);
                    // Message({
                    //     message: "上传失败",
                    //     type: "warning"
                    // });
                }
            );
    });
}

export { SERVICE, SERVICEDEFAULT, upload }